/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { baseInput } from '../../elements/input/styles';
import { routes } from '../../shared/constants';

const StyledContainer = styled.div`
  max-width: 900px;
  margin: 30px auto;
  padding: 0px 40px;

  @media (max-width: ${props => props.theme.components.phoneWidthMax}) {
    padding: 0px 30px;
  } 
`;

const FieldContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: ${props => props.theme.size.large};
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  ${baseInput}
`;

const ForgotPasswordContainer = styled.div`
  text-align: right;
`;

const ForgotPassword = styled.a`
  display: inline-block;
  margin-bottom: 30px;
  font-size: 14px;
`;

const ErrorContainer = styled.div`
  color: ${props => props.theme.colours.red};
  margin: 30px 0px;
`;

export default function LoginForm({
  data
}) {
  const {
    hashId,
    loginHeading,
    submitButton,
    forgotPasswordButton,
    forgotPasswordUrl
  } = data;

  const [errorType, setErrorType] = useState(false);

  const emailRegex = `[a-zA-Z0-9!#$%&-_'*+\/=?^_{|}~.]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$`;
  const passwordRegex = `.{4,}`;

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(document.getElementById(`loginform-${hashId}`));

    fetch(`${routes.global.urls.domains.root}mobile/submitlogin/?__amp_source_origin=https%3A%2F%2F${window.document.domain}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'amp-same-origin': 'true'
      },
      body: formData
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'error') {
          if (response.lockedout) {
            setErrorType('lockedout');
          } else if (response.incorrect) {
            setErrorType('incorrect');
          } else if (response.unknown) {
            setErrorType('unknown');
          } else {
            setErrorType('status');
          }
        } else {
          setErrorType(false);
          window.location.href = `${routes.global.urls.domains.root}account`;
        }
      })
      .catch(() => {
        setErrorType('status');
      });
  };

  return (
    <div id={hashId}>
      <StyledContainer>
        <h1>{loginHeading}</h1>
        <form id={`loginform-${hashId}`} method="post" target="_top" onSubmit={handleSubmit}>
          <FieldContainer>
            <StyledLabel htmlFor="username">Email*</StyledLabel>
            <StyledInput id="username" name="username" type="email" autocomplete="off" pattern={emailRegex} required />
          </FieldContainer>
          <FieldContainer>
            <StyledLabel htmlFor="password">Password*</StyledLabel>
            <StyledInput id="password" name="password" type="password" autocomplete="off" pattern={passwordRegex} required />
          </FieldContainer>

          {forgotPasswordUrl && forgotPasswordUrl && (
            <ForgotPasswordContainer>
              <ForgotPassword href={forgotPasswordUrl}>{forgotPasswordButton}</ForgotPassword>
            </ForgotPasswordContainer>
          )}

          {/* Button */}
          <div className="buttonWrap">
            <input type="submit" className="button btn-green" value={submitButton} />
          </div>

          {/* Errors */}
          {errorType
            && (
              <ErrorContainer>

                {errorType === 'status'
                  && (
                    <div>
                      <b>Oops, there was a problem logging in!</b>
                      <br />
                      <span>
                        Looks like our system is having troubles, have another go or come
                        back later.
                      </span>
                      <br />
                    </div>
                  )}

                {errorType === 'unknown'
                  && (
                    <div>
                      <b>Oops! Something went wrong.</b>
                      <br />
                      <span>We have sent our IT guys a report. Sorry about that.</span>
                      <br />
                    </div>
                  )}

                {errorType === 'incorrect'
                  && (
                    <div>
                      <b>Oops, wrong email or password!</b>
                      <br />
                      Please try again or
                      <a href="/login/forgot-password/">click here to recover.</a>
                      <br />
                    </div>
                  )}

                {errorType === 'lockedout'
                  && (
                    <div>
                      <b>You have been locked out of your account.</b>
                      <br />
                      <span>Please contact our customer service team on 133 156.</span>
                      <br />
                    </div>
                  )}
              </ErrorContainer>
            )}
        </form>
      </StyledContainer>
    </div>
  );
}

LoginForm.propTypes = {
  data: PropTypes.shape({
    hashId: PropTypes.string,
    loginHeading: PropTypes.string,
    submitButton: PropTypes.string,
    forgotPasswordButton: PropTypes.string,
    forgotPasswordUrl: PropTypes.string
  })
};
